/*
 * Servizio che si occupa della ricerca degli elementi e del count totale
*/
module SearchItemsInHomeService {
    let app = angular.module("app");

    app.factory("SearchItemsInHomeService", ["$resource", "GlobalApplicationData", ($resource: ng.resource.IResourceService, GlobalApplicationData: any) => {
        let serv = {
            // Ricerca in base a filtri scelti dall'utente
            getReferenceIdsOfMySurveys: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/get-reference-ids-of-my-surveys', {
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),

            // Ricerca in base a filtri scelti dall'utente
            searchItems: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/search-items/:fromRecord/:numRecords', {
                fromRecord: "@fromRecord",
                numRecords: "@numRecords"
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),
            
            // Recupera gli elementi suggeriti
            suggestedItems: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/get-suggested-items', {
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),
            
            // Recupera le persone suggerite
            suggestedPeople: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/get-persons-of-suggestions', {
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),

            listItemsByAny: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/list-items-by-any/:fromRecord/:numRecords', {
                fromRecord: "@fromRecord",
                numRecords: "@numRecords"
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),

            // Recupera il numero degli elementi rentable
            countItemsByAny: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/count-items-by-any', {
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),

            // Recupera gli elementi delle attività dell'utente
            myActivitiesItems: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/get-my-activities-items/:fromRecord/:numRecords', {
                fromRecord: "@fromRecord",
                numRecords: "@numRecords"
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),

            // Recupera il numero degli elementi rentable
            countRentableItems: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/count-rents-by-any', {
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),

            // Recupera gli elementi rentable
            searchRentableItems: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/get-rents-by-any', {
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),

            countmyActivitiesItems: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/count-my-activities-items', {
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),

            countItems: $resource(GlobalApplicationData.applicationContext + 'rest-api/library-mediator/count-items', {
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            })
        };
        return serv;
    }]);
}